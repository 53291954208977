import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'
import ContactForm from "../../components/ContactForm";
import BasicLayout from "../../components/layout/BasicLayout";
import Content from "../../components/Content";

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  render() {
    return (
        <BasicLayout pageName={"contact"} footerCta={false}>
          <section>
            <h1 className={"copy-header copy-header--xl"}>
              Contact
            </h1>

            <p>Add your details below & hit send. I'll likely be in touch within an hour or two, but may take a little longer (24-48 hours) to respond during busy periods.</p>

            <div className="l-component">
              <ContactForm></ContactForm>
            </div>
          </section>
        </BasicLayout>
    )
  }
}
